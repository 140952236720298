<template>
    <v-container>
        <slot
            name="activator"
            :on-click="upgradePressCableDistributionUnits"
        ></slot>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    components: {}
})
export default class UpgradeCredit extends Vue {
    upgradePressCableDistributionUnits() {
        return this.$http
            .post(`/credits/upgrade_presscable`)
            .then(({ data }) => {
                if (data.data.success) {
                    this.handleUpgradeRequest(data.data);
                } else {
                    this.$store.dispatch(
                        'notification/error',
                        data.meta.errors
                    );
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    handleUpgradeRequest({ redirect }: { redirect?: string }) {
        if (redirect) {
            // payment page
            window.location.href = redirect;
        } else {
            this.$router.push('/app/profile/credits', () => {
                this.$store.dispatch(
                    'notification/success',
                    'PressCable distribution credits upgraded successfully!'
                );
            });
        }
    }
}
</script>
