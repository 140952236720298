<template>
    <v-container v-if="showPressCableUserWelcomeMessage">
        <v-row justify="center">
            <v-dialog v-model="dialog" width="768" persistent>
                <v-card
                    class="modal-wrapper"
                    :class="
                        $vuetify.breakpoint.lgAndUp ? 'rounded-xl' : 'rounded'
                    "
                >
                    <v-card-title class="justify-end pb-0 pr-4 pt-4">
                        <v-btn class="close-btn" icon @click.prevent="close">
                            <v-icon medium>times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-title class="justify-center pa-2">
                        <h3 class="text-h5 font-weight-bold">
                            Welcome to AmpiFire
                        </h3>
                    </v-card-title>
                    <v-card-text>
                        <v-container class="justify-center text-center">
                            <v-img
                                src="@/assets/img/presscable/welcome-user-1.png"
                                max-height="134"
                                max-width="225"
                                class="mx-auto mb-6 mt-4"
                            />
                            <p class="text-content pt-2">
                                AmpiFire can do everything PressCable did and so
                                much more!
                                <br />
                                Your company info and past PressCable Releases
                                are already here.
                                <br />
                                You can review them by clicking on the “My Amps”
                                tab.
                            </p>
                            <p class="text-content mb-10">
                                Let’s create your first Amp so you can
                                experience the power of AmpiFire!
                                <br />
                                Do it yourself OR for even better results or
                                request our Expert Authors to create the Amp for
                                you!
                            </p>
                            <v-card-actions
                                class="justify-center pb-8 pb-sm-10"
                            >
                                <v-btn
                                    class="justify-center"
                                    color="primary"
                                    :to="newRequestLink"
                                    max-width="272"
                                    max-height="45"
                                >
                                    Do An Amp For Me
                                </v-btn>
                                <v-btn
                                    class="justify-center"
                                    :to="newAnnouncementLink"
                                >
                                    Create My Own Amp
                                </v-btn>
                                <upgrade-credit
                                    v-if="hasPressCableDistributionCredits"
                                >
                                    <template #activator="{ onClick }">
                                        <v-btn
                                            class="justify-center"
                                            @click.prevent="onClick"
                                        >
                                            {{ upgradeCreditText }}
                                        </v-btn>
                                    </template>
                                </upgrade-credit>
                            </v-card-actions>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';
import { UpgradeCredit } from '@/components/Credit';

@Component({
    components: {
        UpgradeCredit
    }
})
export default class PressCableUserWelcome extends Vue {
    dialog = false;

    get newAnnouncementLink() {
        return ['/announcements/create', this.userId]
            .filter(Boolean)
            .join('?user_id=');
    }

    get newRequestLink() {
        return ['/author-requests/create', this.userId]
            .filter(Boolean)
            .join('?user_id=');
    }

    get userId() {
        return this.$store.state.user.id;
    }

    get storageKey() {
        return `shown_presscable_user_${this.userId}`;
    }

    get showPressCableUserWelcomeMessage() {
        return this.$store.state.user.showPressCableUserWelcomeMessage;
    }

    get hasPressCableDistributionCredits() {
        return this.$store.state.user.hasPressCableCredits;
    }

    get upgradeCreditText() {
        if (this.$vuetify.breakpoint.lgAndUp) {
            return 'Upgrade to Amps at Heavy Discount';
        }
        return 'Upgrade to Amps';
    }

    mounted() {
        this.dialog = this.showWelcomePressCableUserDialog();
    }

    showWelcomePressCableUserDialog() {
        return (
            this.showPressCableUserWelcomeMessage &&
            !this.hasSeenWelcomePressCableUserDialog()
        );
    }

    hasSeenWelcomePressCableUserDialog() {
        const localStorage = window.localStorage;
        if (!localStorage) {
            return false;
        }
        return localStorage.getItem(this.storageKey);
    }

    close() {
        this.dialog = false;
    }

    @Watch('dialog')
    onDialogChange(value: boolean) {
        if (value) {
            localStorage.setItem(this.storageKey, `${this.userId}`);
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
    .close-btn {
        color: #dfdbdb;
    }

    .text-content {
        font-size: 1.125em;
        font-weight: 400;
        max-width: 50ch;
        margin-inline: auto;
    }
    .create-btn {
        width: 272px;
    }

    .btn-height {
        height: 45px;
    }
}

.v-card__text {
    padding-bottom: 0;
}

.v-card__actions {
    padding-top: 0 !important;
    gap: 1.25rem;
    flex-wrap: wrap;

    .v-btn {
        margin-left: 0 !important;
    }

    .container {
        padding: 0 !important;
        width: auto;
        margin-inline: 0;
    }
}
</style>
